import React from "react";
import { createContext } from "react";
import PropTypes from "prop-types";
import { client } from "../services/axiosClient";
import { jwtDecode } from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setAuthTokens,
  setUser,
  setUserDetails,
} from "../reducers/authReducer";
import { baseUrl } from "utils/utils";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const authTokens = useSelector((state) => state.auth.authTokens);
  const user = useSelector((state) => state.auth.user);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const dispatch = useDispatch();
  const history = useHistory();

  let loginUser = async (e, setLoginError, setIsLoggedIn, redir) => {
    e.preventDefault();

    const credentials = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    try {
      const response = await client.post(baseUrl + "token/", credentials, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response?.data) {
        setLoginError("Invalid response from server");
        return;
      }

      const { data } = response;

      dispatch(setAuthTokens(data));
      dispatch(setUser(jwtDecode(data.access)));
      setIsLoggedIn(true);

      if (redir && userDetails) {
        history.push(redir);
        return;
      }

      dispatch(setUserDetails(null));
      setTimeout(() => history.push("/welcome"), 600);
    } catch (error) {
      if (!error.response) {
        setLoginError("Error connecting to server");
        return;
      }

      const { status, data } = error.response;

      if (status === 429) {
        setLoginError(
          data?.message ||
            "Too many failed attempts. Please try again in 15 minutes.",
        );
        return;
      }
      setLoginError(data?.detail || "Authentication failed");
    }
  };

  let logoutUser = (e, error) => {
    if (e) e.preventDefault();
    dispatch(setAuthTokens(null));
    dispatch(setUser(null));
    dispatch(setUserDetails(null));
    history.push("/auth/login", error ? { error } : {});
  };

  let contextData = {
    user: user,
    userDetails: userDetails,
    authTokens: authTokens,
    loginUser: loginUser,
    logoutUser: logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
