import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import AuthContext from "../../contexts/AuthProvider";
import cx from "classnames";
import { client, fileDownloadClient } from "../../services/axiosClient";
import fileDownload from "js-file-download";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// material-ui icons
import { CloudDownload } from "@material-ui/icons";
import { faFolder, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Table from "../../components/Table/Table.js";
import { ThreeDots } from "react-loader-spinner";

import { grayColor } from "../../assets/jss/material-dashboard-pro-react";

const downloadsCenterStyle = () => ({
  name_col: {
    width: "75%",
  },
  last_modified_col: {
    width: "25%",
  },
  action: {
    cursor: "pointer",
  },
  icon: {
    display: "inline-block",
    width: "28px",
    textAlign: "center",
  },
  iconReturn: {
    color: "#888888",
    minWidth: "28px",
    fontSize: "16px",
    // opacity: "0",
  },
  iconFolder: {
    color: "#ffdd33",
    minWidth: "28px",
    fontSize: "16px",
  },
  iconFile: {
    color: "#888888",
    minWidth: "28px",
    fontSize: "16px",
  },
  actionText: {
    transition: "all 0.2s ease",
    color: grayColor[1],
    "&:hover": {
      color: grayColor[7],
    },
    "&.return": {
      fontStyle: "italic",
    },
  },
  fileSpinner: {
    "& > svg": {
      paddingTop: "2px",
    },
  },
  unavailable: {
    opacity: "0.7",
    pointerEvents: "none",
  },
});

import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DownloadsCenter() {
  let { userDetails } = useContext(AuthContext);

  const client_styles = () => ({
    ...downloadsCenterStyle(),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [downloadingFile, setDownloadingFile] = useState(null);
  const [directory, setDirectory] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const updateFiles = () => {
    setIsLoading(true);
    const params = { directory };
    client
      .post("api/downloads_center_view_files/", params)
      .then((response) => {
        setFiles(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const downloadFile = (file_name) => {
    setDownloadingFile(file_name);
    const params = {
      directory,
      file_name,
    };
    client.post("api/awake/").then(() => {
      fileDownloadClient
        .post("api/downloads_center_download_file/", params)
        .then((response) => {
          fileDownload(
            response.data,
            response.headers["content-disposition"].split("filename=")[1],
          );
          setDownloadingFile(null);
        })
        .catch((error) => {
          console.log(error);
          setDownloadingFile(null);
        });
    });
  };

  useEffect(() => {
    updateFiles();
  }, []);

  useLayoutEffect(() => {
    updateFiles();
  }, [directory]);

  const loadingSpinner = () => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ThreeDots
          width="100px"
          height="100px"
          color={userDetails.implementation_color}
          ariaLabel="loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  };

  const filesWithActions = () => {
    let fwa = [];
    if (isLoading) return fwa;
    if (directory.length > 0) {
      fwa.push({
        name: t("modeling_downloads_center.return"),
        last_modified: "NaT",
        type: "back",
        action: "back",
      });
    }
    fwa = fwa.concat(files);
    for (let i = 0; i < fwa.length; i++) {
      fwa[i].action = fwa[i].action
        ? fwa[i].action
        : fwa[i].type === "folder"
          ? "open"
          : "download";
    }
    return fwa;
  };
  const handleItemClick = (name, action) => {
    if (action === "back") {
      setDirectory((curDirectory) => curDirectory.slice(0, -1));
    } else if (action === "open") {
      setDirectory((curDirectory) => [...curDirectory, name]);
    } else if (action === "download") {
      downloadFile(name);
    }
  };
  const getFileNameSpan = (file) => {
    return (
      <>
        <span
          className={cx({
            [classes.actionText]: true,
          })}
        >
          {file.name}
          {downloadingFile === file.name && (
            <i>
              {" — "}
              {t("modeling_downloads_center.waiting_for_download")}
            </i>
          )}
        </span>
      </>
    );
  };
  const tableData = filesWithActions().map((file, key) => {
    return [
      <div
        key={key}
        className={classes.action}
        onClick={() => handleItemClick(file.name, file.action)}
      >
        <div className={classes.icon}>
          {file.type === "back" && (
            <span className={classes.iconReturn}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
          )}
          {file.type === "folder" && (
            <span className={classes.iconFolder}>
              <FontAwesomeIcon icon={faFolder} />
            </span>
          )}
          {file.type === "file" && downloadingFile !== file.name && (
            <span className={classes.iconFile}>
              <FontAwesomeIcon icon={faFile} />
            </span>
          )}
          {file.type === "file" && downloadingFile === file.name && (
            <span className={classes.iconFile}>
              <ThreeDots
                color={grayColor[0]}
                ariaLabel="loading"
                width={"20px"}
                height={"12px"}
                wrapperStyle={{}}
                wrapperClass={classes.fileSpinner}
              />
            </span>
          )}
        </div>{" "}
        {file.type === "file" && (
          <Tooltip title={t("modeling_downloads_center.click_to_download")}>
            {getFileNameSpan(file)}
          </Tooltip>
        )}
        {file.type !== "file" && getFileNameSpan(file)}
      </div>,
      <div key={key}>
        {file.last_modified === "NaT" ? "" : file.last_modified}
      </div>,
    ];
  });

  return (
    <div>
      <GridContainer style={{ marginTop: "-40px" }}>
        <GridItem xs={12}>
          <div>
            <Card>
              <CardHeader color="client" icon>
                <CardIcon color="client">
                  <CloudDownload />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("modeling_downloads_center.downloads_center")}
                </h4>
              </CardHeader>
              <CardBody
                className={cx({
                  [classes.cardBody]: true,
                  [classes.unavailable]: downloadingFile,
                })}
              >
                <Table
                  tableHead={[
                    t("modeling_downloads_center.name"),
                    t("modeling_downloads_center.last_modified"),
                  ]}
                  tableData={tableData}
                  customHeadCellClasses={[
                    classes.name_col,
                    classes.last_modified_col,
                  ]}
                  customHeadClassesForCells={[0, 1]}
                  customCellClasses={[
                    classes.name_col,
                    classes.last_modified_col,
                  ]}
                  customClassesForCells={[0, 1]}
                />
                {isLoading && loadingSpinner()}
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
