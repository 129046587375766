import React from "react";
import { useContext } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

import styles from "assets/jss/material-dashboard-pro-react/components/customLinearProgressStyle.js";
import AuthContext from "../../contexts/AuthProvider";

export default function CustomLinearProgress(props) {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);

  const classes = useStyles();
  const { color, rootClasses, barClasses, ...rest } = props;
  return (
    <LinearProgress
      {...rest}
      classes={{
        root:
          classes.root +
          " " +
          classes[color + "Background"] +
          " " +
          rootClasses,
        bar: classes.bar + " " + classes[color] + " " + barClasses,
      }}
    />
  );
}

CustomLinearProgress.defaultProps = {
  color: "gray",
  rootClasses: "",
  barClasses: "",
};

CustomLinearProgress.propTypes = {
  color: PropTypes.oneOf([
    "client",
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  rootClasses: PropTypes.string,
  barClasses: PropTypes.string,
};
