import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/css/TextEditorCSSOverride.css";
import { makeStyles } from "@material-ui/core/styles";
import { grayColor } from "../../assets/jss/material-dashboard-pro-react";

const styles = () => {
  return {
    wrapper: {
      // margin: "2px 0",
      border: "1px solid #f1f1f1",
      // padding: "5px",
      borderRadius: "4px",
    },
    toolbar: {
      border: "none",
      borderBottom: "1px solid #f1f1f1",
    },
    editor: {
      fontSize: "12px",
      lineHeight: "1.4",
      minHeight: "200px",
      padding: "4px",
      backgroundColor: grayColor[12],
    },
  };
};

export default function TextEditor(props) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { initialContent, readOnly, setContent } = props;

  useEffect(() => {
    if (initialContent) {
      const blocksFromHtml = htmlToDraft(initialContent);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [initialContent]);

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
  };

  return (
    <Editor
      editorState={editorState}
      readOnly={readOnly}
      toolbarHidden={readOnly}
      wrapperClassName={classes.wrapper}
      toolbarClassName={classes.toolbar}
      editorClassName={classes.editor}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "remove",
          "history",
        ],
      }}
    />
  );
}

TextEditor.defaultProps = {
  readOnly: true,
};

TextEditor.propTypes = {
  initialContent: PropTypes.string,
  readOnly: PropTypes.bool,
  setContent: PropTypes.func.isRequired,
};
