import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { useImmer } from "use-immer";
import AuthContext from "../../contexts/AuthProvider";
import "moment/locale/es";
import "moment/locale/pt";
import cx from "classnames";
import { client } from "../../services/axiosClient";
import { useTranslation } from "react-i18next";
import { objectsEqual } from "../../utils/utils";

import DraggableCanvas from "../../components/Draggable/DraggableCanvas";
import Treemap from "../../components/Graphs/Treemap";
import Pnl from "../../components/Pnl/Pnl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import { AttachMoney, HelpOutline, PhotoCamera } from "@material-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import ActionBar from "components/ActionBar/ActionBar";
import ActionDropdown from "../../components/ActionDropdown/ActionDropdown";

import styles from "../../assets/jss/material-dashboard-pro-react/views/graphExplorerStyle";

const NUM_GROUPS = 5;

export default function GraphExplorer() {
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isTreemapLoading, setIsTreemapLoading] = useState(false);

  // structure & selection states
  const [dimensions, setDimensions] = useState(["placeholder"]);
  const [dimensionNames, setDimensionNames] = useState({
    placeholder: { en: "placeholder", es: "placeholder", pt: "placeholder" },
  });
  const [groupsName, setGroupsName] = useState("");

  const [levels, setLevels] = useImmer(
    Array.from({ length: NUM_GROUPS }, (_, i) => i).reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {}),
  );
  const [selection, setSelection] = useImmer({});
  const [pnlSelection, setPnlSelection] = useImmer({});
  const levelsCount = useRef(0);
  const childrenLevel = useRef(null);
  const [parentLevel, setParentLevel] = useState(-1);

  const [selectedBox, setSelectedBox] = useImmer({
    display: false,
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    parentName: null,
    childrenName: null,
  });
  const [treemapData, setTreemapData] = useState([]);
  const totalNodes = useRef(0);

  // P&L
  const volumeUnit = useRef("");
  const plStructure = useRef([]);
  const netRevenueAnchor = useRef("");
  const serveMarginAnchor = useRef("");
  const revenueAnchors = useRef([]);
  const volumeL12M = useRef(null);
  const avgServeMargin = useRef(0);
  const [plRefreshing, setPlRefreshing] = useState(false);
  const [plValues, setPlValues] = useState({ 1: { volume: 0 } });
  const [plName, setPlName] = useState(userDetails.implementation_name);
  const [followMeText, setFollowMeText] = useState([
    userDetails.implementation_name,
  ]);
  const [lastFollowMeText, setLastFollowMeText] = useState([
    userDetails.implementation_name,
  ]);
  const followMeTextTimer = useRef(null);
  const lastRefreshPnlParams = useRef("");
  const lastRefreshTreemapParams = useRef({});

  // other states
  const [currencyUnit, setCurrencyUnit] = useState("");
  const currencyUnitOptions = useRef([]);
  const currencyUnitOptionsAvailable = useRef([]);

  const getUnitOptions = () => {
    let options = [];
    currencyUnitOptions.current.map((opt) => {
      let available = currencyUnitOptionsAvailable.current.includes(opt);
      options.push({
        identified: {
          currency: opt,
          value_type: "value",
        },
        text:
          opt +
          (available
            ? ""
            : " (" +
              t("results.missing_rates") +
              (["DEV", "IMG", "CAD", "CFA", "CST"].includes(
                userDetails.user_role,
              )
                ? " - " + t("results.missing_rates_complement")
                : "") +
              ")"),
        active: opt === currencyUnit,
        disabled: !available,
      });
    });
    return options;
  };
  const handleUnitSelection = (opt) => {
    const { currency } = opt;
    if (currency !== currencyUnit) {
      setCurrencyUnit(currency);
    }
  };

  const refreshPL = () => {
    if (isLoading) return;
    let pl_filters = {};
    let pl_filters_mode = {};
    let pl_filters_sequence = [];
    let pl_name = [userDetails.implementation_name];

    Object.values(levels)
      .filter((val) => val !== null)
      .forEach((val) => {
        if (Object.keys(pnlSelection).includes(val)) {
          pl_filters[val] = [pnlSelection[val]];
          pl_filters_mode[val] = "items";
          pl_filters_sequence.push(val);
        }
        if (Object.keys(pnlSelection).includes(val)) {
          pl_name.push(pnlSelection[val]);
        }
      });
    const params = {
      currency_unit: currencyUnit,
      common_filters: { period: ["l12m"] },
      common_filters_mode: { period: "group" },
      common_filters_sequence: ["period"],
      pl_filters,
      pl_filters_mode,
      pl_filters_sequence,
    };
    if (JSON.stringify(params) === lastRefreshPnlParams.current) return;
    lastRefreshPnlParams.current = JSON.stringify(params);
    setPlRefreshing(true);
    setPlName(pl_name.join(" > "));
    client
      .post("api/results_pl_explorer_read_pl/", params)
      .then((response) => {
        setPlValues({ 1: response.data.pl });
        setPlRefreshing(false);
      })
      .catch((error) => {
        console.log(error);
        setPlRefreshing(false);
      });
  };
  const refreshTreemap = () => {
    if (isLoading) return;
    const params = {
      level1: levels[parentLevel],
      level2: null,
      filters: selection,
      currency_unit: currencyUnit,
    };
    if (
      JSON.stringify(params) ===
      JSON.stringify(lastRefreshTreemapParams.current)
    )
      return;
    lastRefreshTreemapParams.current = structuredClone(params);
    setIsTreemapLoading(true);
    client
      .post("api/results_graph_explorer_get_treemap_values/", params)
      .then((response) => {
        setTreemapData(response.data.treemap_data);
        totalNodes.current = response.data.total_nodes;
        setIsTreemapLoading(false);
        setSelectedBox((draft) => {
          draft.display = false;
        });
      })
      .catch((error) => {
        console.log(error);
        setIsTreemapLoading(false);
      });
  };

  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));
    client
      .post("api/results_graph_explorer_meta/")
      .then((response) => {
        setDimensions(response.data.dimensions);
        setDimensionNames(response.data.dimension_names);
        volumeUnit.current = response.data.volume_unit;
        currencyUnitOptions.current = response.data.currency_unit_options;
        currencyUnitOptionsAvailable.current =
          response.data.currency_unit_options_available;
        setCurrencyUnit(response.data.default_currency_unit);
        plStructure.current = response.data.pl_structure;
        netRevenueAnchor.current = response.data.net_revenue_anchor;
        serveMarginAnchor.current = response.data.serve_margin_anchor;
        revenueAnchors.current = response.data.revenue_anchors;
        volumeL12M.current = response.data.volume_l12m;
        avgServeMargin.current = response.data.avg_serve_margin;
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setGroupsName(t("results.graph_explorer.level"));
  }, [i18n.language]);

  useEffect(() => {
    if (isLoading) return;

    levelsCount.current = Object.values(levels).filter(
      (val) => val !== null,
    ).length;

    let selection_changed = false;
    let new_selection = structuredClone(selection);
    Object.keys(selection).forEach((key) => {
      if (!Object.values(levels).slice(0, parentLevel).includes(key)) {
        selection_changed = true;
        delete new_selection[key];
      }
    });
    if (selection_changed) {
      setSelection(new_selection);
      setPnlSelection(new_selection);
      return;
    }

    if (levelsCount.current > 0 && parentLevel === -1) {
      setParentLevel(0);
      return;
    }

    if (levelsCount.current === parentLevel) {
      setParentLevel(levelsCount.current - 1);
      return;
    }

    if (levelsCount.current === 0) {
      setTreemapData([]);
      lastRefreshTreemapParams.current = {};
      setSelectedBox((draft) => {
        draft.display = false;
      });
    } else {
      if (
        Object.values(levels)
          .slice(0, parentLevel + 1)
          .every((val) => Object.keys(selection).includes(val))
      ) {
        let temp_selection = structuredClone(selection);
        delete temp_selection[levels[parentLevel]];
        if (
          !objectsEqual(
            temp_selection,
            lastRefreshTreemapParams.current.filters,
          )
        ) {
          refreshTreemap();
        }
      } else {
        refreshTreemap();
      }
    }

    refreshPL();
  }, [levels, selection, pnlSelection, parentLevel, currencyUnit]);

  const refreshFollowMeText = () => {
    let text_arr = [userDetails.implementation_name];
    Object.values(levels).forEach((val) => {
      if (!val || !selection[val]) return;
      text_arr.push(`${dimensionNames[val][i18n.language]}: ${selection[val]}`);
    });
    return text_arr;
  };
  useLayoutEffect(() => {
    const newFollowMeText = refreshFollowMeText();
    if (newFollowMeText === followMeText) return;
    setFollowMeText(newFollowMeText);
    clearTimeout(followMeTextTimer.current);
    if (newFollowMeText.length > lastFollowMeText.length) {
      followMeTextTimer.current = setTimeout(() => {
        setLastFollowMeText(newFollowMeText);
      }, 0);
    } else {
      setLastFollowMeText(newFollowMeText);
    }
  }, [levels, selection, i18n.language]);

  const graphOptions = (
    <>
      {/* Unit */}
      <ActionDropdown
        button={
          <GridItem className={classes.actionWrapperOuter}>
            <div
              className={cx({
                [classes.actionWrapper]: true,
                [classes.actionDisabled]: isLoading,
              })}
            >
              <div className={classes.actionIcon}>
                <AttachMoney />
              </div>
              <h6 className={classes.actionText}>{t("results.unit")}</h6>
            </div>
          </GridItem>
        }
        dropdownList={getUnitOptions()}
        onClick={handleUnitSelection}
      />
      {/* Take photo */}
      <GridItem className={classes.actionWrapperOuter}>
        <div
          className={cx({
            [classes.actionWrapper]: true,
            [classes.actionDisabled]: isLoading,
            [classes.actionDisabled]: true,
          })}
        >
          <div className={classes.actionIcon}>
            <PhotoCamera />
          </div>
          <h6 className={classes.actionText}>{t("results.take_photo")}</h6>
        </div>
      </GridItem>
      {/* Help */}
      <GridItem className={classes.actionWrapperOuter}>
        <div
          className={cx({
            [classes.actionWrapper]: true,
            [classes.actionDisabled]: isLoading,
            [classes.actionDisabled]: true,
          })}
        >
          <div className={classes.actionIcon}>
            <HelpOutline />
          </div>
          <h6 className={classes.actionText}>{t("results.help")}</h6>
        </div>
      </GridItem>
    </>
  );

  const followMe = (
    <>
      {followMeText.map((value, key) => (
        <div key={key} className={classes.followMe}>
          <span
            className={cx({
              [classes.followMeText]: true,
              ["new"]: !lastFollowMeText.includes(value),
            })}
          >
            {value}
          </span>
          {key + 1 < followMeText.length && (
            <span className={classes.followMeDivider}>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          )}
        </div>
      ))}
    </>
  );

  return (
    <div>
      <Hidden smDown>
        <ActionBar actions={graphOptions} spinnerIn={false} />
      </Hidden>
      <div>
        <GridContainer style={{ marginTop: "-28px" }}>
          <Hidden mdUp>
            <GridItem xs={12}>
              <ActionBar actions={graphOptions} spinnerIn={false} sm />
            </GridItem>
          </Hidden>
          <GridItem xs={12}>
            <Card style={{ marginBottom: "0" }}>
              <CardBody style={{ padding: "8px" }}>
                <DraggableCanvas
                  isLoading={isLoading}
                  dimensions={dimensions}
                  dimensionNames={dimensionNames}
                  groupsName={groupsName}
                  levels={levels}
                  setLevels={setLevels}
                  disabled={isTreemapLoading}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card style={{ marginTop: "8px", marginBottom: "0" }}>
              <CardBody style={{ padding: "6px" }}>{followMe}</CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={8}>
            <Card className={classes.dataCard}>
              <CardBody
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "6px 2px 2px 2px",
                }}
              >
                <Treemap
                  isLoading={isLoading || isTreemapLoading}
                  levels={levels}
                  dimensionNames={dimensionNames}
                  setSelection={setSelection}
                  setPnlSelection={setPnlSelection}
                  levelsCount={levelsCount}
                  parentLevel={parentLevel}
                  setParentLevel={setParentLevel}
                  childrenLevel={childrenLevel}
                  data={treemapData}
                  totalNodes={totalNodes}
                  avgServeMargin={avgServeMargin}
                  selectedBox={selectedBox}
                  setSelectedBox={setSelectedBox}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={4}>
            <Card className={classes.dataCard}>
              <CardBody style={{ padding: "0" }}>
                <Pnl
                  plStructure={plStructure}
                  netRevenueAnchor={netRevenueAnchor}
                  serveMarginAnchor={serveMarginAnchor}
                  plName={plName}
                  isRefreshing={plRefreshing}
                  plValues={plValues}
                  volumeUnit={volumeUnit}
                  volumeL12M={volumeL12M}
                  currencyUnit={currencyUnit}
                  isLoading={isLoading}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

GraphExplorer.propTypes = {};
