/*eslint-disable*/
import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";
import AuthContext from "../../contexts/AuthProvider";
import { useSelector } from "react-redux";
import { store } from "../../store";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment/moment";
import cx from "classnames";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast, Slide as ToastSlide } from "react-toastify";
import fileDownload from "js-file-download";
import { setProgress } from "../../reducers/fileTransferReducer";
import {
  client,
  fileUploadClient,
  fileDownloadClient,
} from "../../services/axiosClient";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

// material-ui icons
import {
  Alarm,
  Assignment,
  AssignmentTurnedInRounded,
  Cached,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckRounded,
  Close,
  CloudUploadRounded,
  DeleteForever,
  Done,
  DoneAllRounded,
  ErrorOutline,
  FilterNone,
  GetApp,
  Help,
  HighlightOff,
  HourglassEmpty,
  InsertDriveFile,
  LinkRounded,
  Memory,
  Notes,
  Search,
  Timer,
  TimerOff,
  WarningRounded,
} from "@material-ui/icons";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Accordion from "../../components/Accordion/Accordion.js";
import Button from "../../components/CustomButtons/Button";
import FloatingLoading from "../../components/FloatingItems/FloatingLoading";
import TextEditor from "../../components/TextEditor/TextEditor";
import CustomLinearProgress from "../../components/CustomLinearProgress/CustomLinearProgress";
import HelpTooltip from "../../components/HelpTooltip/HelpTooltip";

import {
  cardTitle,
  grayColor,
  successColor,
  dangerColor,
  whiteColor,
  warningColor,
  blendColors,
  hexToRgb,
  blackColor,
  infoColor,
} from "../../assets/jss/material-dashboard-pro-react";
import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle";
import customDropdownStyle from "../../assets/jss/material-dashboard-pro-react/components/customDropdownStyle";
const styles = (theme, clientColor) => {
  return {
    ...modalStyle(theme),
    ...customDropdownStyle(theme, clientColor),
    inputsCardWrapper: {
      width: "100%",
      overflow: "hidden",
      "@media (max-width: 1320px)": {
        "&:not(.loading)": {
          overflow: "auto !important",
        },
      },
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px",
    },
    chipWaiting: {
      background:
        "linear-gradient(60deg, " + grayColor[5] + ", " + grayColor[4] + ")",
    },
    chipInProgress: {
      background:
        "linear-gradient(60deg, " +
        successColor[5] +
        ", " +
        successColor[6] +
        ")",
    },
    chipSuccess: {
      color: whiteColor,
      background:
        "linear-gradient(60deg, " +
        successColor[1] +
        ", " +
        successColor[2] +
        ")",
    },
    chipWarning: {
      color: whiteColor,
      background:
        "linear-gradient(60deg, " +
        warningColor[1] +
        ", " +
        warningColor[2] +
        ")",
    },
    chipError: {
      color: whiteColor,
      background:
        "linear-gradient(60deg, " +
        dangerColor[1] +
        ", " +
        dangerColor[2] +
        ")",
    },
    chipIcon: {
      color: whiteColor,
    },
    blocksSeparator: {
      marginTop: "10px",
      borderTop: "2px dotted " + blendColors(clientColor, whiteColor, 0.5),
    },
    inputBox: {
      display: "inline-block",
      marginRight: "10px",
      backgroundColor: grayColor[19] + "bb",
      borderRadius: "6px",
      padding: "8px",
      width: "150px",
      height: "150px",
      position: "relative",
      cursor: "pointer",
      transition: "all 0.2s",
      "&:hover": {
        transform: "scale(1.06)",
        backgroundColor: grayColor[19] + "ff",
      },
      "&.error": {
        color: whiteColor,
        backgroundColor: "#dd0000",
      },
      "&.running": {
        backgroundColor: grayColor[10],
        backgroundImage:
          "linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)",
        backgroundSize: "15px 15px",
        animation: "$move 2s linear infinite",
      },
    },
    fileStatus: {
      position: "absolute",
      top: "87%",
      width: "26px",
      height: "26px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transform: "translate(-50%,-50%)",
      backgroundColor: grayColor[3],
      color: whiteColor,
      borderRadius: "6px",
      "&.success": {
        backgroundColor: successColor[3],
      },
      "&.warning": {
        backgroundColor: warningColor[3],
      },
      "&.error": {
        backgroundColor: dangerColor[3],
      },
      "&.running": {
        backgroundColor: grayColor[1],
        backgroundImage:
          "linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)",
        backgroundSize: "15px 15px",
        animation: "$move 2s linear infinite",
        color: whiteColor,
      },
    },
    "@keyframes move": {
      "0%": {
        backgroundPosition: "0 0",
      },
      "100%": {
        backgroundPosition: "15px 15px",
      },
    },
    drawerHeader: {
      borderBottom: "1px solid " + clientColor,
      padding: "4px 12px",
      backgroundColor: grayColor[15],
    },
    accordionRoot: {
      "& > div:first-child": {
        paddingTop: "24px",
      },
    },
    accordionTitle: {
      paddingLeft: "12px",
      color: grayColor[7],
      "&:hover,&:focus": {
        color: grayColor[6],
      },
    },
    expandIcon: {
      color: grayColor[7],
      "&:hover,&:focus": {
        color: grayColor[6],
      },
    },
    inputDetailsTopic: {
      margin: "4px 0",
    },
    inputDetailsTopicName: {
      color: grayColor[3],
    },
    inputDetailsTopicContent: {
      // color: grayColor[0],
    },
    inputDetailsTopicFunctionIcon: {
      cursor: "pointer",
      "& > svg": {
        width: "24px",
      },
    },
    inputDetailsSaveDescriptionButton: {
      width: "100px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "6px 0",
      height: "24px",
      cursor: "pointer",
      backgroundColor: clientColor,
      color: whiteColor,
      padding: "0 4px",
      borderRadius: "4px",
      "&.saving": {
        opacity: "0.5",
        pointerEvents: "none",
      },
    },
    connectionsWrapper: {
      // display: "flex",
    },
    connection: {
      display: "inline-block",
      fontSize: "12px",
      padding: "0 4px",
      backgroundColor: grayColor[19],
      borderRadius: "4px",
      marginRight: "4px",
      marginBottom: "4px",
      cursor: "pointer",
      transition: "all 0.2s",
      "&.ready": {
        backgroundColor: blendColors(successColor[0], grayColor[19], 0.15),
      },
      "&:hover": {
        boxShadow: "0 0 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.25)",
      },
    },
    inputStatusWrapper: {
      margin: "6px 2px 2px",
      // padding: "0 6px",
      width: "100%",
      // border: "1px solid #f1f1f1",
      borderRadius: "4px",
      display: "flex",
      textAlign: "center",
    },
    inputStatusItem: {
      display: "flex",
      flex: "1",
      alignItems: "center",
      justifyContent: "center",
      padding: "4px 0 !important",
      backgroundColor: grayColor[3],
      color: whiteColor,
      borderRadius: "4px",
      "&.not-next": {
        backgroundColor: grayColor[4],
      },
      "&.success": {
        backgroundColor: successColor[3],
      },
      "&.warning": {
        backgroundColor: warningColor[3],
      },
      "&.error": {
        backgroundColor: dangerColor[3],
      },
      "&.running": {
        backgroundColor: grayColor[1],
        backgroundImage:
          "linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)",
        backgroundSize: "15px 15px",
        animation: "$move 2s linear infinite",
        color: whiteColor,
      },
    },
    inputStatusIcon: {
      marginTop: "6px",
      color: grayColor[0],
      "&.waiting": {
        color: grayColor[4],
      },
    },
    pipelineInProgressBar: {
      fontSize: "11px",
      fontStyle: "italic",
      textAlign: "center",
      width: "100%",
      backgroundColor: grayColor[10],
      backgroundImage:
        "linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)",
      backgroundSize: "15px 15px",
      animation: "$move 2s linear infinite",
    },
    displayNotesTable: {
      width: "100%",
      fontSize: "12px",
    },
    displayNotesTableHeader: {
      color: whiteColor,
      backgroundColor: grayColor[7],
    },
    displayNotesTableRow: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      lineHeight: "1.25",
      minHeight: "28px",
      padding: "12px 0",
      "&:not($displayNotesTableHeader)": {
        borderBottom: "1px solid " + grayColor[11],
      },
      "& > div": {
        position: "relative",
      },
    },
    displayNotesTableColCheckStage: {
      width: "14%",
      textAlign: "center",
    },
    displayNotesTableColSeverity: {
      width: "9%",
      textAlign: "center",
      "&.severity1": {
        color: infoColor[0],
      },
      "&.severity2": {
        color: warningColor[0],
      },
      "&.severity3": {
        color: dangerColor[0],
      },
    },
    displayNotesTableColDescription: {
      width: "10%",
      textAlign: "center",
    },
    displayNotesTableColComments: {
      width: "57%",
      textAlign: "left",
    },
    displayNotesTableColCopyValues: {
      width: "10%",
      textAlign: "center",
    },
    displayNotesTableIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "16px",
      height: "16px",
      cursor: "pointer",
      color: grayColor[1],
      transition: "all 0.2s",
      "&:hover": {
        color: grayColor[6],
      },
    },
    customProgressBarRoot: {
      borderRadius: "3px",
    },
    customProgressBarBar: {
      height: "6px !important",
    },
    monthlyStatus: {
      [theme.breakpoints.up("md")]: {
        position: "fixed",
        top: "0",
        left: "50%",
        transform: "translate(-50%,0)",
        zIndex: "1200",
        minWidth: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: whiteColor,
        padding: "4px 24px 2px",
        borderRadius: "0 0 8px 8px",
        borderStyle: "solid",
        borderWidth: "0 1px 1px 1px",
        borderColor: grayColor[8],
        whiteSpace: "nowrap",
      },
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "18px 0 4px",
      },
    },
    downloadInProgress: {
      backgroundColor: blendColors(whiteColor, clientColor, 0.25),
      backgroundImage:
        "linear-gradient(-45deg, rgba(255, 255, 255, 0.25) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 75%, transparent 75%, transparent)",
      backgroundSize: "15px 15px",
      animation: "$move 3s linear infinite",
      color: whiteColor,
      pointerEvents: "none",
    },
    inlineButton: {
      color: grayColor[0],
      margin: "0 2px",
      padding: "0",
      height: "24px",
      width: "36px",
      borderRadius: "4px",
      transition: "all 0.2s",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "&.disabled": {
        color: blendColors(grayColor[0], whiteColor, 0.4),
        cursor: "auto",
      },
      "&:hover:not(.disabled)": {
        backgroundColor: blendColors(grayColor[0], whiteColor, 0.2),
      },
    },
    uploadProgressBar: {
      width: "100%",
      overflow: "hidden",
      textAlign: "center",
      transition: "all 0.2s",
      height: "0",
      "&.show": {
        height: "40px",
      },
    },
    attention: {
      animation: "$attention 3s ease infinite",
      animationDelay: "0.5s",
      transformOrigin: "top center",
    },
    "@keyframes attention": {
      "10%": {
        transform: "scale(1.1) translate(0,-50%)",
      },
      "13.33%,20%": {
        transform: "rotate(-10deg) scale(1.1) translate(0,-50%)",
      },
      "16.67%": {
        transform: "rotate(10deg) scale(1.1) translate(0,-50%)",
      },
      "23.33%": {
        transform: "rotate(0deg) scale(1.1) translate(0,-50%)",
      },
      "33%": {
        transform: "scale(1) translate(0,-50%)",
      },
    },
    largeTooltip: {
      fontSize: "12px",
    }
  };
};

const tabsStyles = () => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  // eslint-disable-next-line no-unused-vars
  indicator: (props) => ({
    backgroundColor: grayColor[0],
  }),
});
const tabStyles = () => ({
  root: (props) => ({
    color:
      props.status === "system_error"
        ? dangerColor[0]
        : props.status === "complete"
          ? successColor[4]
          : grayColor[0],
    opacity: "0.5",
    minWidth: "0",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "none",
    fontWeight: "normal",
    transition: "all 0.15s ease",
    "&:hover": {
      opacity: "1",
      color:
        props.status === "system_error"
          ? dangerColor[0]
          : props.status === "complete"
            ? successColor[4]
            : grayColor[0],
    },
  }),
  selectedTab: (props) => ({
    opacity: "1",
    transform: "scale(1.2)",
    color:
      (props.status === "system_error"
        ? dangerColor[0]
        : props.status === "complete"
          ? successColor[4]
          : grayColor[0]) + " !important",
  }),
});
const CustomTabs = withStyles(tabsStyles)(
  // eslint-disable-next-line no-unused-vars
  ({ classes, client_color, tabs, ...other }) => (
    <Tabs
      {...other}
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
    >
      {tabs}
    </Tabs>
  ),
);
// eslint-disable-next-line no-unused-vars
const CustomTab = withStyles(tabStyles)(({ classes, status, ...other }) => (
  <Tab
    {...other}
    classes={{
      root: classes.root,
      selected: classes.selectedTab,
    }}
  />
));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Inputs() {
  // region initial declarations
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  // endregion

  // region toasts
  const notify = (text, time = 3000, type = "info") =>
    toast(text, {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: type,
      transition: ToastSlide,
    });

  const notifyCopy = () =>
    toast(t("tasks.values_copied"), {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: "info",
      transition: ToastSlide,
    });
  // endregion

  // region state declarations
  const [pipelineInProgress, setPipelineInProgress] = useState(false);
  const [monthlyStatus, setMonthlyStatus] = useImmer([]);

  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedMonthData, setSelectedMonthData] = useState(null);
  const [inputsStatus, setInputsStatus] = useImmer([]);
  const [inputDrawerInput, setInputDrawerInput] = useState(null);
  const [
    inputDrawerShowExpectedFileContent,
    setInputDrawerShowExpectedFileContent,
  ] = useState(true);

  const [isRefreshing, setIsRefreshing] = useState(true);
  const controller = useRef(null);
  const autoRefreshTimeout = useRef(null);
  const lastRefreshParams = useRef("");

  const [inputDetailsContentEdit, setInputDetailsContentEdit] = useState("");
  const [displayNotesModalOpen, setDisplayNotesModalOpen] = useState(false);
  const [resetInputsModalOpen, setResetInputsModalOpen] = useState(false);

  const [isSavingInputDescription, setIsSavingInputDescription] =
    useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [wrongDataType, setWrongDataType] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [replicatingFile, setReplicatingFile] = useState(false);

  const [downloadButtonPopperOpen, setDownloadButtonPopperOpen] =
    useState(null);
  const [downloadInProgress, setDownloadInProgress] = useState(null);

  const fileUploadProgress = useSelector(
    () => store.getState().file_transfer.progress,
  );
  // endregion

  // region useEffect's
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);
  useEffect(() => {
    if (selectedMonthData === null) {
      setSelectedMonth(Math.max(monthlyStatus.length - 1, 0));
      setSelectedMonthData(
        monthlyStatus.length > 1
          ? monthlyStatus[monthlyStatus.length - 1]
          : null,
      );
    }
  }, [monthlyStatus]);
  useEffect(() => {
    const pipeline = isPipelineInProgress();
    if (pipeline !== pipelineInProgress) setPipelineInProgress(pipeline);
  }, [inputsStatus]);
  useEffect(() => {
    refreshInputsStatus();
  }, [selectedMonth]);
  useEffect(() => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    setFileName("");
    setWrongDataType(false);
  }, [inputDrawerInput]);
  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));
    client
      .post("api/inputs_months_v2/")
      .then((response) => {
        setMonthlyStatus(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  // endregion

  // region functions
  const isPipelineInProgress = (is = inputsStatus) => {
    let response = false;
    is.forEach((input_group) => {
      input_group.inputs.forEach((input) => {
        if (
          input.stages.data_structure === "running" ||
          input.stages.cross_linking === "running"
        ) {
          response = true;
        }
      });
    });
    return response;
  };
  const hasSystemError = () => {
    if (selectedMonthData && selectedMonthData.status === "system_error")
      return true;
    let response = false;
    inputsStatus.forEach((input_group) => {
      input_group.inputs.forEach((input) => {
        if (
          input.stages.data_structure === "system_error" ||
          input.stages.cross_linking === "system_error"
        ) {
          response = true;
        }
      });
    });
    return response;
  };
  const saveInputDetails = () => {
    if (isSavingInputDescription) return false;
    // console.log(inputDetailsContentEdit);
    const params = {
      input_id: inputDrawerInput.input_id,
      description: inputDetailsContentEdit,
    };
    setIsSavingInputDescription(true);
    client
      .post("api/inputs_save_input_description/", params)
      .then((response) => {
        // save monthly status
        let found = false;
        for (let i = 0; i < inputsStatus.length; i++) {
          for (let j = 0; j < inputsStatus[i].inputs.length; j++) {
            if (
              inputsStatus[i].inputs[j].input_id === inputDrawerInput.input_id
            ) {
              setInputsStatus((draft) => {
                draft[i].inputs[j].input_details = inputDetailsContentEdit;
              });
              found = true;
              break;
            }
          }
          if (found) break;
        }
        setIsSavingInputDescription(false);
        notify("Input description saved");
      })
      .catch((error) => {
        console.log(error);
        setIsSavingInputDescription(false);
        notify("Error on saving input description", 5000, "error");
      });
  };
  const refreshInputsStatus = (silent = false) => {
    if (!selectedMonthData) return false;
    if (!silent) {
      setInputsStatus([]);
    }
    setIsRefreshing(true);
    const params = {
      month: selectedMonthData.month,
      year: selectedMonthData.year,
    };
    lastRefreshParams.current = JSON.stringify(params);
    clearTimeout(autoRefreshTimeout.current);
    if (controller.current) controller.current.abort();
    controller.current = new AbortController();
    client
      .post("api/inputs_status_v2/", params, {
        signal: controller.current.signal,
      })
      .then((response) => {
        if (JSON.stringify(params) === lastRefreshParams.current) {
          setInputsStatus(response.data.inputs);
          setMonthlyStatus((draft) => {
            const index = draft.findIndex(
              (item) =>
                item.month === selectedMonthData.month &&
                item.year === selectedMonthData.year,
            );
            draft[index] = response.data.monthly_status;
          });
          setSelectedMonthData(response.data.monthly_status);
          if (isPipelineInProgress(response.data.inputs)) {
            autoRefreshTimeout.current = setTimeout(() => {
              refreshInputsStatus(true);
            }, 3000);
          } else {
            clearTimeout(autoRefreshTimeout.current);
          }
          setIsRefreshing(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsRefreshing(false);
      });
  };
  const getInputConnections = (connection_ids) => {
    let connections = [];
    inputsStatus.forEach((input_group) => {
      input_group.inputs.forEach((input) => {
        if (connection_ids.includes(input.input_id)) {
          connections.push(input);
        }
      });
    });
    return (
      <div className={classes.connectionsWrapper}>
        {connections.map((conn, key) => {
          return (
            <Tooltip
              title={t("modeling_inputs_v2.click_to_open")}
              enterDelay={500}
              enterNextDelay={500}
              key={key}
            >
              <span
                key={key}
                className={cx({
                  [classes.connection]: true,
                  ["ready"]:
                    conn.stages.data_source === "success" &&
                    ["success", "warning"].includes(
                      conn.stages.data_structure,
                    ) &&
                    ["waiting", "success", "warning"].includes(
                      conn.stages.cross_linking,
                    ),
                })}
                onClick={() => handleConnectionClick(conn)}
              >
                {conn.input_name[i18n.language]}
              </span>
            </Tooltip>
          );
        })}
      </div>
    );
  };
  // endregion

  // region handlers
  const handleMonthSelectionChange = (e, selectedMonth) => {
    if (isUploading) {
      notify("Por favor aguarde até o término do upload", 3000, "warning");
      return false;
    }
    setSelectedMonth(selectedMonth);
    setSelectedMonthData(monthlyStatus[selectedMonth]);
  };
  const handleConnectionClick = (conn) => {
    setInputDrawerShowExpectedFileContent(false);
    setInputDrawerInput(conn);
  };
  const handleShowExpectedFileContentClick = (e) => {
    e.stopPropagation();
    setInputDrawerShowExpectedFileContent((cur) => !cur);
  };
  const handleConfirmResetInput = () => {
    setInputsStatus([]);
    setIsRefreshing(true);
    let params = {
      month: selectedMonthData.month,
      year: selectedMonthData.year,
    };
    client
      .post("api/inputs_reset_month_inputs/", params)
      .then(() => {
        setResetInputsModalOpen(false);
        refreshInputsStatus();
      })
      .catch((error) => {
        console.log(error);
        setIsRefreshing(false);
      });
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragLeave = () => {
    setIsDragging(false);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) handleFileSelect(file);
  };
  const handleFileSelect = (file) => {
    if (
      file.name.substring(file.name.lastIndexOf(".") + 1) !==
      inputDrawerInput.expected_file_type
    ) {
      setWrongDataType(true);
    } else {
      setWrongDataType(false);
    }
    setFileName(file.name);
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    fileInputRef.current.files = dataTransfer.files;
  };
  const handleReplicateFileClick = () => {
    setIsUploading(true);
    setReplicatingFile(true);
    let params = {
      input_control_id: inputDrawerInput.input_control_id,
    };
    client
      .post("api/inputs_replicate_file/", params)
      .then((response) => {
        if (response.data.success) {
          setIsUploading(false);
          setReplicatingFile(false);
          setInputDrawerInput(null);
          notify(t("modeling_inputs_v2.upload_completed"), 3000);
          refreshInputsStatus();
        } else {
          setIsUploading(false);
          setReplicatingFile(false);
          notify(
            t("modeling_inputs_v2.errors." + response.data.error_code),
            5000,
            "error",
          );
        }
      })
      .catch((error) => {
        setIsUploading(false);
        setReplicatingFile(false);
        console.log(error);
      });
  };
  const handleUploadFileClick = () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", fileInputRef.current.files[0]);
    formData.append("input_control_id", inputDrawerInput.input_control_id);
    client.post("api/awake/").then(() => {
      fileUploadClient
        .post("api/inputs_upload/", formData)
        .then((response) => {
          if (response.data.success) {
            setIsUploading(false);
            setInputDrawerInput(null);
            notify(t("modeling_inputs_v2.upload_completed"), 3000);
            refreshInputsStatus();
          } else {
            setIsUploading(false);
            store.dispatch(setProgress(0));
            notify(
              t("modeling_inputs_v2.errors." + response.data.error_code),
              5000,
              "error",
            );
          }
        })
        .catch((error) => {
          setIsUploading(false);
          store.dispatch(setProgress(0));
          console.log(error);
        });
    });
  };
  const handleViewNotesClick = () => {
    if (inputDrawerInput.notes.length > 0) {
      setDisplayNotesModalOpen(true);
    } else {
      notify(t("modeling_inputs_v2.no_notes_notification"), 2000);
    }
  };
  const handleDownloadLastUploadedFileButton = (event) => {
    if (
      downloadButtonPopperOpen &&
      downloadButtonPopperOpen.contains(event.target)
    ) {
      setDownloadButtonPopperOpen(null);
    } else {
      setDownloadButtonPopperOpen(event.currentTarget);
    }
  };
  const handleDownloadFileSelection = (option) => {
    if (downloadInProgress !== null) return false;
    const params = {
      input_control_id: inputDrawerInput.input_control_id,
      option,
    };
    setDownloadInProgress(option);
    client.post("api/awake/").then(() => {
      fileDownloadClient
        .post("api/inputs_download/", params)
        .then((response) => {
          fileDownload(
            response.data,
            response.headers["content-disposition"].split("filename=")[1],
          );
          setDownloadButtonPopperOpen(null);
          setDownloadInProgress(null);
        })
        .catch((error) => {
          console.log(error);
          setDownloadButtonPopperOpen(null);
          setDownloadInProgress(null);
        });
    });
  };
  const handleClickAwayDownloadFileSelection = (event) => {
    if (downloadInProgress !== null) return false;
    if (typeof event.target.className === "string") {
      if (event.target.className.includes("dropdownItem")) return false;
    }
    setDownloadButtonPopperOpen(null);
  };
  const handleRefreshInputsClick = () => {
    if (isRefreshing) return false;
    refreshInputsStatus(true);
  };
  const handleResetInputsClick = () => {
    if (isRefreshing || inputsStatus.length === 0) return false;
    setResetInputsModalOpen(true);
  };
  // endregion

  // region component fragments
  const monthsTabs = monthlyStatus.map((month, key) => (
    <CustomTab
      key={key}
      label={
        t("months." + month.month.toString()) +
        "-" +
        month.year.toString().slice(-2)
      }
      status={month.status}
    />
  ));
  const monthSelectionTabs = () => {
    return (
      <CustomTabs
        client_color={userDetails.implementation_color}
        value={selectedMonth}
        onChange={handleMonthSelectionChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="month selection tabs"
        tabs={monthsTabs}
      />
    );
  };
  const getMonthProgressIcon = (progress) => {
    switch (progress) {
      case "verifying":
        return <HourglassEmpty />;
      case "unknown":
        return <Help className={classes.chipIcon} />;
      case "waiting":
        return <Alarm />;
      case "running":
        return <Memory />;
      case "complete":
        return <CheckCircle className={classes.chipIcon} />;
      case "error":
        return <ErrorOutline className={classes.chipIcon} />;
      case "system_error":
        return <TimerOff className={classes.chipIcon} />;
    }
  };
  const inputBox = (input, key) => {
    return (
      // Outer box
      <div
        key={key}
        className={cx({
          [classes.inputBox]: true,
          ["error"]: Object.values(input.stages).some(
            (element) => element === "system_error",
          ),
          ["running"]: Object.values(input.stages).some(
            (element) => element === "running",
          ),
        })}
        onClick={() => setInputDrawerInput(input)}
      >
        {/* Input name */}
        <div
          style={{
            position: "absolute",
            top: "6px",
            left: "6px",
            right: "6px",
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "1.25",
          }}
        >
          {input.input_name[i18n.language]}
        </div>
        {/* File icon */}
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            top: "52%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: "78px",
            // color: grayColor[1],
          }}
        >
          <InsertDriveFile
            style={{
              height: "100%",
              width: "100%",
              opacity: "0.6",
            }}
          />
        </div>
        {/* File type */}
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            top: "62%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            color: whiteColor,
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"." + input.expected_file_type}
        </div>
        {/* Data source status */}
        <Tooltip title={t("modeling_inputs_v2.data_source")}>
          <div
            className={cx({
              [classes.fileStatus]: true,
              [input.stages.data_source]: true,
            })}
            style={{ left: "28%" }}
          >
            <CloudUploadRounded
              style={{
                height: "80%",
                width: "80%",
              }}
            />
          </div>
        </Tooltip>
        {/* Data structure status */}
        <Tooltip title={t("modeling_inputs_v2.data_structure")}>
          <div
            className={cx({
              [classes.fileStatus]: true,
              [input.stages.data_structure]: true,
            })}
            style={{ left: "50%" }}
          >
            <AssignmentTurnedInRounded
              style={{
                height: "80%",
                width: "80%",
              }}
            />
          </div>
        </Tooltip>
        {/* Cross-linking status */}
        <Tooltip title={t("modeling_inputs_v2.cross_linking")}>
          <div
            className={cx({
              [classes.fileStatus]: true,
              [input.stages.cross_linking]: true,
            })}
            style={{ left: "72%" }}
          >
            <LinkRounded
              style={{
                height: "80%",
                width: "80%",
              }}
            />
          </div>
        </Tooltip>
        {/* Readiness icons */}
        <div
          style={{
            position: "absolute",
            right: "10px",
            top: "48%",
            transform: "translate(0,-50%)",
            width: "32px",
            height: "32px",
          }}
        >
          {input.stages.data_source === "success" &&
            ["success", "warning"].includes(input.stages.data_structure) &&
            input.stages.cross_linking === "waiting" && (
              <Tooltip title={t("modeling_inputs_v2.file_sent")}>
                <CheckRounded
                  style={{
                    color: successColor[3],
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Tooltip>
            )}
          {["success", "warning"].includes(input.stages.cross_linking) && (
            <Tooltip title={t("modeling_inputs_v2.file_sent_and_checked")}>
              <DoneAllRounded
                style={{
                  color: successColor[3],
                  height: "100%",
                  width: "100%",
                }}
              />
            </Tooltip>
          )}
          {(["error", "system_error"].includes(input.stages.data_structure) ||
            ["error", "system_error"].includes(input.stages.cross_linking)) && (
            <Tooltip title={t("modeling_inputs_v2.data_invalidated")}>
              <WarningRounded
                style={{
                  color: dangerColor[3],
                  height: "100%",
                  width: "100%",
                }}
              />
            </Tooltip>
          )}
          {input.stages.data_source === "waiting" &&
            input.available_data_from && (
              <Tooltip
                title={
                  t("modeling_inputs_v2.available_data_from") +
                  " " +
                  t("months." + input.available_data_from[0].toString()) +
                  "-" +
                  input.available_data_from[1].toString().slice(-2)
                }
              >
                <Cached
                  style={{
                    color: successColor[3],
                    opacity: "0.6",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Tooltip>
            )}
        </div>
      </div>
    );
  };
  const inputsComponent = () => {
    return inputsStatus.map((input_group, key1) => {
      return (
        <Card
          key={key1}
          style={{
            minWidth: "1200px",
            marginTop: "8px",
            marginBottom: "0px",
          }}
        >
          <CardBody
            style={{
              padding: "0 10px 3px 10px",
            }}
          >
            <div
              style={{
                color: grayColor[3],
                backgroundColor: grayColor[8] + "44",
                margin: "0 -10px 10px -10px",
                borderBottom:
                  "1px solid " +
                  blendColors(
                    userDetails.implementation_color,
                    whiteColor,
                    0.25,
                  ),
                textAlign: "center",
                borderRadius: "6px 6px 0 0",
                lineHeight: "2",
              }}
            >
              {input_group.group_name[i18n.language]}
            </div>
            <div
              style={{
                marginTop: "8px",
              }}
            >
              {input_group.inputs.map((input, key2) => {
                return inputBox(input, key2);
              })}
            </div>
          </CardBody>
        </Card>
      );
    });
  };
  const downloadButtonPopper = (cleaned_up_file) => {
    return (
      <Popper
        open={Boolean(downloadButtonPopperOpen) || downloadInProgress !== null}
        anchorEl={downloadButtonPopperOpen}
        transition
        disablePortal
        placement="bottom"
        className={classNames({
          [classes.popperClose]: !downloadButtonPopperOpen,
          [classes.popperResponsive]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            id="download-file-menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener
                onClickAway={handleClickAwayDownloadFileSelection}
              >
                <MenuList
                  role="menu"
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <MenuItem
                    onClick={() => handleDownloadFileSelection("original")}
                    className={cx({
                      [classes.dropdownItem]: true,
                      [classes.clientHover]: downloadInProgress === null,
                      [classes.downloadInProgress]:
                        downloadInProgress === "original",
                    })}
                  >
                    {t("modeling_inputs_v2.download_original")}
                  </MenuItem>
                  {cleaned_up_file && (
                    <MenuItem
                      onClick={() => handleDownloadFileSelection("cleaned_up")}
                      className={cx({
                        [classes.dropdownItem]: true,
                        [classes.clientHover]: downloadInProgress === null,
                        [classes.downloadInProgress]:
                          downloadInProgress === "cleaned_up",
                      })}
                    >
                      {t("modeling_inputs_v2.download_cleaned_up")}
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };
  const inputDrawer = () => {
    return (
      <Drawer
        anchor={"right"}
        open={inputDrawerInput !== null}
        onClose={() => setInputDrawerInput(null)}
        BackdropProps={{ style: { backgroundColor: "#000", opacity: "0.1" } }}
        PaperProps={{
          style: {
            borderRadius: "10px",
            margin: "1vh 1vh 1vh 0",
            height: "98vh",
            width: "720px",
            overflowX: "hidden",
          },
        }}
      >
        <>
          {inputDrawerInput && (
            <>
              <div
                className={cx({
                  [classes.drawerHeader]: true,
                })}
              >
                <h4>
                  {inputDrawerInput.input_name[i18n.language]}
                  <span style={{ color: grayColor[3] }}>
                    {" | "}
                    {t("months." + selectedMonthData.month.toString()) +
                      "-" +
                      selectedMonthData.year.toString().slice(-2)}
                  </span>
                </h4>
              </div>
              {pipelineInProgress && (
                <div className={classes.pipelineInProgressBar}>
                  {t("modeling_inputs_v2.pipeline_in_progress")}
                </div>
              )}
              <div style={{ margin: "0" }}>
                <Accordion
                  collapses={[
                    {
                      title: t("modeling_inputs_v2.input_status_and_actions"),
                      content: inputStatusContentV2,
                    },
                    {
                      title: t("modeling_inputs_v2.input_details"),
                      content: inputDetailsContent,
                    },
                  ]}
                  customRootClass={classes.accordionRoot}
                  customTitleClass={classes.accordionTitle}
                  customExpandIcon={classes.expandIcon}
                  active={0}
                />
              </div>
            </>
          )}
        </>
      </Drawer>
    );
  };
  const displayNotesDialog = () => {
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={displayNotesModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDisplayNotesModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setDisplayNotesModalOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <div
            style={{
              marginTop: "-8px",
              paddingTop: "4px",
              paddingBottom: "8px",
            }}
          >
            <h4 className={classes.modalTitle}>
              {inputDrawerInput.input_name[i18n.language]}
            </h4>
            <h6 className={classes.modalTitle}>
              {t("modeling_inputs_v2.modals.display_notes.input_notes")}{" "}
              <span className={classes.modalTitleSecondary}>
                ({t("modeling_inputs_v2.modals.display_notes.system_generated")}
                )
              </span>
            </h6>
          </div>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <div className={classes.displayNotesTable}>
            <div
              className={cx({
                [classes.displayNotesTableHeader]: true,
                [classes.displayNotesTableRow]: true,
              })}
            >
              <div className={classes.displayNotesTableColCheckStage}>
                {t("modeling_inputs_v2.modals.display_notes.check_stage")}
              </div>
              <div className={classes.displayNotesTableColSeverity}>
                {t("modeling_inputs_v2.modals.display_notes.severity")}
              </div>
              <div className={classes.displayNotesTableColDescription}>
                {t("modeling_inputs_v2.modals.display_notes.description")}
              </div>
              <div className={classes.displayNotesTableColComments}>
                {t("modeling_inputs_v2.modals.display_notes.comments")}
              </div>
              <div className={classes.displayNotesTableColCopyValues}>
                {t("modeling_inputs_v2.modals.display_notes.copy_values")}
              </div>
            </div>
            <div className={classes.displayNotesTableContent}>
              {inputDrawerInput.notes.map((note, key) => {
                return (
                  <div key={key} className={classes.displayNotesTableRow}>
                    <div className={classes.displayNotesTableColCheckStage}>
                      {t("modeling_inputs_v2.stages." + note.type)}
                    </div>
                    <div
                      className={cx({
                        [classes.displayNotesTableColSeverity]: true,
                        ["severity" + note.severity]: true,
                      })}
                    >
                      {t(
                        "modeling_inputs_v2.modals.display_notes.severity" +
                          note.severity,
                      )}
                    </div>
                    <div className={classes.displayNotesTableColDescription}>
                      <Tooltip title={note.description[i18n.language]}>
                        <Search className={classes.displayNotesTableIcon} />
                      </Tooltip>
                    </div>
                    <div className={classes.displayNotesTableColComments}>
                      {note.notes[i18n.language]}
                    </div>
                    <div className={classes.displayNotesTableColCopyValues}>
                      {note.values && (
                        <CopyToClipboard text={note.values} onCopy={notifyCopy}>
                          <Tooltip
                            title={t(
                              "modeling_inputs_v2.modals.display_notes.copy_related_values",
                            )}
                          >
                            <FilterNone
                              className={classes.displayNotesTableIcon}
                            />
                          </Tooltip>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setDisplayNotesModalOpen(false)}
            color="danger"
            simple
          >
            {t("modeling_inputs_v2.modals.display_notes.close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const resetInputsDialog = () => {
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={resetInputsModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setResetInputsModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setResetInputsModalOpen(false)}
            disabled={inputsStatus.length === 0}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {t("modeling_inputs_v2.restart_month")}
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div style={{ marginBottom: "16px" }}>
            {t("modeling_inputs_v2.modals.restart_inputs.notice")}
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => setResetInputsModalOpen(false)}
            color="black"
            simple
            disabled={inputsStatus.length === 0}
          >
            {t("modeling_inputs_v2.modals.restart_inputs.cancel")}
          </Button>
          <Button
            onClick={() => handleConfirmResetInput()}
            color="danger"
            simple
            disabled={inputsStatus.length === 0}
          >
            {t("modeling_inputs_v2.modals.restart_inputs.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const monthlyStatusDiv = () => {
    return (
      <div className={classes.monthlyStatus}>
        <div
          style={{
            borderRight: "1px solid " + grayColor[0],
            paddingRight: "8px",
            marginRight: "8px",
          }}
        >
          {t("months." + selectedMonthData.month.toString()) +
            "-" +
            selectedMonthData.year.toString().slice(-2)}
        </div>
        {t("modeling_inputs_v2.month_status.month_calculation_progress")}
        {":"}
        <Tooltip
          title={t(
            "modeling_inputs_v2.month_status.tooltip." +
              selectedMonthData.status,
          )}
        >
          <Chip
            style={{ marginLeft: "8px", marginBottom: "2px" }}
            className={cx({
              [classes.chipWarning]: selectedMonthData.status === "unknown",
              [classes.chipWaiting]:
                selectedMonthData.status === "verifying" ||
                selectedMonthData.status === "waiting",
              [classes.chipInProgress]: selectedMonthData.status === "running",
              [classes.chipError]:
                selectedMonthData.status === "system_error" ||
                selectedMonthData.status === "error",
              [classes.chipSuccess]: selectedMonthData.status === "complete",
            })}
            size="small"
            icon={getMonthProgressIcon(selectedMonthData.status)}
            label={t(
              "modeling_inputs_v2.month_status." + selectedMonthData.status,
            )}
          />
        </Tooltip>
        {hasSystemError() && (
          <Chip
            style={{ marginLeft: "8px", marginBottom: "2px" }}
            className={classes.chipError}
            size="small"
            label={t("modeling_inputs_v2.contact_integration_team")}
          />
        )}
        {!hasSystemError() && (
          <>
            <span
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                color: grayColor[0],
              }}
            >
              {t("modeling_inputs_v2.total_inputs") +
                ": " +
                selectedMonthData.total_inputs +
                " | " +
                t("modeling_inputs_v2.sent") +
                ": " +
                selectedMonthData.inputs_sent +
                " | " +
                t("modeling_inputs_v2.validated") +
                ": " +
                selectedMonthData.inputs_verified}
            </span>
            <Tooltip title={t("modeling_inputs_v2.refresh")}>
              <div
                className={cx({
                  [classes.inlineButton]: true,
                  ["disabled"]: isRefreshing || inputsStatus.length === 0,
                })}
                onClick={() => handleRefreshInputsClick()}
              >
                {!isRefreshing && <Cached />}
                {isRefreshing && (
                  <ThreeDots
                    color={grayColor[0]}
                    height={"8px"}
                    width={"30px"}
                    wrapperStyle={{
                      height: "8px",
                      width: "30px",
                    }}
                  />
                )}
              </div>
            </Tooltip>
            <Tooltip title={t("modeling_inputs_v2.restart_month_modeling")}>
              <div
                className={cx({
                  [classes.inlineButton]: true,
                  ["disabled"]:
                    isRefreshing ||
                    inputsStatus.length === 0 ||
                    isPipelineInProgress(),
                })}
                onClick={() => handleResetInputsClick()}
              >
                <DeleteForever />
              </div>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const uploadingText = replicatingFile
    ? t("modeling_inputs_v2.replicating")
    : fileUploadProgress < 100
      ? t("modeling_inputs_v2.uploading")
      : t("modeling_inputs_v2.please_wait");

  const inputDetailsContent = inputDrawerInput && (
    <div
      style={{
        padding: "0 12px",
        width: "100%",
      }}
    >
      <div className={classes.inputDetailsTopic}>
        <span className={classes.inputDetailsTopicName}>
          {t("modeling_inputs_v2.source.source") + ": "}
        </span>
        <span className={classes.inputDetailsTopicContent}>
          {t("modeling_inputs_v2.source." + inputDrawerInput.source)}
        </span>
      </div>
      <div className={classes.inputDetailsTopic}>
        <span className={classes.inputDetailsTopicName}>
          {t("modeling_inputs_v2.frequency.frequency") + ": "}
        </span>
        <span className={classes.inputDetailsTopicContent}>
          {t("modeling_inputs_v2.frequency." + inputDrawerInput.frequency)}
        </span>
      </div>
      <div className={classes.inputDetailsTopic}>
        <span className={classes.inputDetailsTopicName}>
          {t("modeling_inputs_v2.file_type") + ": "}
        </span>
        <span className={classes.inputDetailsTopicContent}>
          {"." + inputDrawerInput.expected_file_type}
        </span>
      </div>
      <div className={classes.inputDetailsTopic}>
        <span className={classes.inputDetailsTopicName}>
          {t("modeling_inputs_v2.expected_content") + ": "}
        </span>
        <span
          className={cx({
            [classes.inputDetailsTopicContent]: true,
            [classes.inputDetailsTopicFunctionIcon]: true,
          })}
          onClick={handleShowExpectedFileContentClick}
        >
          {inputDrawerShowExpectedFileContent ? (
            <FontAwesomeIcon icon={faEye} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} />
          )}
        </span>
      </div>
      <div
        style={{
          width: "100%",
          display: inputDrawerShowExpectedFileContent ? "block" : "none",
        }}
      >
        <TextEditor
          initialContent={inputDrawerInput.input_details}
          setContent={setInputDetailsContentEdit}
          readOnly={!["DEV", "IMG"].includes(userDetails.user_role)}
        />
        {["DEV", "IMG"].includes(userDetails.user_role) && (
          <div
            className={cx({
              [classes.inputDetailsSaveDescriptionButton]: true,
              ["saving"]: isSavingInputDescription,
            })}
            onClick={() => saveInputDetails()}
          >
            {!isSavingInputDescription && (
              <span>{t("modeling_inputs_v2.save")}</span>
            )}
            {isSavingInputDescription && (
              <ThreeDots color={whiteColor} height={10} />
            )}
          </div>
        )}
      </div>
      <div className={classes.inputDetailsTopic}>
        <span className={classes.inputDetailsTopicName}>
          {t("modeling_inputs_v2.connections") + ": "}
        </span>
        {inputDrawerInput.connections.length === 0 && (
          <span className={classes.inputDetailsTopicContent}>
            <em>{t("modeling_inputs_v2.no_connections")}</em>
          </span>
        )}
      </div>
      {inputDrawerInput.connections.length > 0 && (
        <div style={{ margin: "-3px -2px 0 -2px" }}>
          {getInputConnections(inputDrawerInput.connections)}
        </div>
      )}
    </div>
  );

  const inputStatusContentV2 = inputDrawerInput && (
    <div style={{ width: "100%", display: "block" }}>
      {selectedMonthData.status === "complete" && (
        <div
          style={{
            textAlign: "center",
            backgroundColor: dangerColor[4] + "22",
            fontSize: "12px",
            borderRadius: "4px",
            margin: "8px 6px",
          }}
        >
          {t("modeling_inputs_v2.month_modeling_finished")}
        </div>
      )}
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align={"center"} style={{ width: "5%", paddingLeft: "0", paddingRight: "0" }}>
                {/*{"1"}*/}
                {inputDrawerInput.stages.data_source === "waiting" && (
                  <Timer
                    className={cx({
                      [classes.inputStatusIcon]: true,
                    })}
                  />
                )}
                {inputDrawerInput.stages.data_source !== "waiting" && (
                  <Done
                    className={cx({
                      [classes.inputStatusIcon]: true,
                    })}
                  />
                )}
              </TableCell>
              <TableCell style={{ padding: "6px 6px 6px 0", height: "1px" }}>
                <Tooltip
                  title={t("modeling_inputs_v2.help.source." + inputDrawerInput.source)}
                  placement={"left"}
                  arrow
                  PopperProps={{
                    style: { marginRight: "-6px" },
                  }}
                  classes={{ tooltip: classes.largeTooltip }}
                >
                  <div style={{ display: "flex", height: "100%", cursor: "help" }}>
                    <div
                      className={cx({
                        [classes.inputStatusItem]: true,
                        [inputDrawerInput.stages.data_source]: true,
                      })}
                    >
                      <CloudUploadRounded
                        style={{
                          marginRight: "6px",
                        }}
                      />
                      {t("modeling_inputs_v2.stages.data_source.file_upload")}
                    </div>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell style={{ paddingTop: "6px", paddingLeft: "6px", paddingRight: "12px" }}>
                {inputDrawerInput.updated_by_username && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: "12px",
                      marginBottom: "12px",
                      borderBottom: "1px solid #eeeeee",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "11px",
                        lineHeight: "1.6",
                        backgroundColor: grayColor[15],
                        borderRadius: "4px",
                      }}
                    >
                      <div>
                        {t("modeling_inputs_v2.sent_by") +
                          " " +
                          inputDrawerInput.updated_by_username +
                          " " +
                          t("modeling_inputs_v2.in") +
                          " " +
                          moment
                            .utc(inputDrawerInput.updated_in, "YYYY-MM-DD")
                            .format("l") +
                          " " +
                          t("modeling_inputs_v2.with") +
                          " " +
                          (inputDrawerInput.data_from
                            ? inputDrawerInput.data_from[0] ===
                                selectedMonthData.month &&
                              inputDrawerInput.data_from[1] ===
                                selectedMonthData.year
                              ? t("modeling_inputs_v2.new_data")
                              : t("modeling_inputs_v2.data_from") +
                                " " +
                                inputDrawerInput.data_from[0] +
                                "/" +
                                inputDrawerInput.data_from[1]
                            : t("modeling_inputs_v2.new_data"))}
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ textAlign: "center", textTransform: "uppercase", fontSize: "11px" }}>
                  {t("modeling_inputs_v2.send_new_file")}
                </div>
                <div
                  style={{
                    height: "90px",
                    margin: "0 0 8px 0",
                    border: "1px dashed #d1d1d1",
                    borderRadius: "4px",
                    position: "relative",
                    backgroundColor: isDragging
                      ? grayColor[13]
                      : wrongDataType
                        ? dangerColor[0] + "11"
                        : fileName
                          ? successColor[0] + "11"
                          : whiteColor,
                  }}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept={"." + inputDrawerInput.expected_file_type}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (e.target.files[0]) handleFileSelect(e.target.files[0])
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "46%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      fontSize: "16px",
                      maxWidth: "65%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      pointerEvents: isDragging ? "none" : "auto",
                    }}
                  >
                    {fileName ||
                      "[ " + t("modeling_inputs_v2.no_file_selected") + " ]"}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "80%",
                      left: "50%",
                      transform: "translate(-50%,-50%)",
                      fontSize: "12px",
                      fontStyle: "italic",
                      color: grayColor[0],
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      pointerEvents: isDragging ? "none" : "auto",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    }}
                  >
                    <span
                      style={{
                        color: userDetails.implementation_color,
                        textDecoration: "underline",
                        cursor: "pointer",
                        pointerEvents: isDragging ? "none" : "auto",
                      }}
                      onClick={() => fileInputRef.current.click()}
                    >
                      {t("modeling_inputs_v2.choose_a_file")}
                    </span>
                    {" " + t("modeling_inputs_v2.or_drag_and_drop_here")}
                  </div>
                  {wrongDataType && (
                    <div
                      style={{
                        position: "absolute",
                        top: "20%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        fontSize: "11px",
                        color: whiteColor,
                        backgroundColor: dangerColor[0],
                        borderRadius: "4px",
                        lineHeight: "1",
                        padding: "2px 4px",
                        pointerEvents: isDragging ? "none" : "auto",
                      }}
                    >
                      {t("modeling_inputs_v2.wrong_file_type")}
                    </div>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "25px",
                      transform: "translate(0,-50%)",
                      width: "40px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pointerEvents: isDragging ? "none" : "auto",
                    }}
                    className={cx({
                      [classes.attention]: !wrongDataType && fileName !== "" && !isUploading && !pipelineInProgress,
                    })}
                  >
                    <Tooltip title={t("modeling_inputs_v2.send")}>
                      <Button
                        simple={wrongDataType || fileName === ""}
                        justIcon
                        color={"client"}
                        onClick={() => handleUploadFileClick()}
                        disabled={
                          isUploading ||
                          pipelineInProgress ||
                          wrongDataType ||
                          fileName === ""
                        }
                      >
                        <CloudUploadRounded
                          style={{ width: "30px", height: "30px" }}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ marginRight: "6px" }}>
                    {t("modeling_inputs_v2.or")}
                  </span>
                  <Button
                    subtle
                    color={"client"}
                    size={"sm"}
                    style={{ margin: "0 4px" }}
                    onClick={() => handleReplicateFileClick()}
                    disabled={
                      isUploading || !inputDrawerInput.available_data_from
                    }
                  >
                    <Cached style={{ width: "15px", height: "15px" }} />
                    {t("modeling_inputs_v2.use_previous_file") +
                      " (" +
                      (inputDrawerInput.available_data_from
                        ? t(
                            "months." +
                              inputDrawerInput.available_data_from[0].toString(),
                          ) +
                          "-" +
                          inputDrawerInput.available_data_from[1]
                            .toString()
                            .slice(-2)
                        : t("modeling_inputs_v2.not_available")) +
                      ")"}
                  </Button>
                </div>
                <div
                  className={cx({
                    [classes.uploadProgressBar]: true,
                    ["show"]: isUploading,
                  })}
                >
                  <CustomLinearProgress
                    style={{ margin: "16px 8px 2px", height: "6px" }}
                    variant={
                      replicatingFile ? "indeterminate" : "determinate"
                    }
                    color="client"
                    value={fileUploadProgress}
                    rootClasses={classes.customProgressBarRoot}
                    barClasses={classes.customProgressBarBar}
                  />
                  <div style={{ fontSize: "11px", textTransform: "uppercase" }}>{uploadingText}</div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "12px",
                    marginTop: "12px",
                    borderTop: "1px solid #eeeeee",
                  }}
                >
                  <Button
                    subtle
                    color={"client"}
                    size={"sm"}
                    style={{ margin: "0" }}
                    disabled={
                      isUploading ||
                      (inputDrawerInput.restricted &&
                        userDetails.restricted_data_privilege) ||
                      inputDrawerInput.stages.data_source !== "success"
                    }
                    onClick={handleDownloadLastUploadedFileButton}
                    aria-owns={
                      downloadButtonPopperOpen
                        ? "download-file-menu-list"
                        : null
                    }
                    aria-haspopup={"true"}
                  >
                    <GetApp style={{ width: "15px", height: "15px" }} />
                    {t("modeling_inputs_v2.download_last_file_sent")}
                  </Button>
                </div>
                {downloadButtonPopper(
                  ["success", "warning"].includes(
                    inputDrawerInput.stages.data_structure,
                  ),
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align={"center"} style={{ width: "5%", paddingLeft: "0", paddingRight: "0" }}>
                {/*{"2"}*/}
                {inputDrawerInput.stages.data_structure === "waiting" && (
                  <Timer
                    className={cx({
                      [classes.inputStatusIcon]: true,
                      ["waiting"]: true,
                    })}
                  />
                )}
                {["success", "warning"].includes(
                    inputDrawerInput.stages.data_structure,
                  ) && (
                    <Done
                      className={cx({
                        [classes.inputStatusIcon]: true,
                      })}
                    />
                  )}
                {["error", "system_error"].includes(
                    inputDrawerInput.stages.data_structure,
                  ) && (
                    <HighlightOff
                      className={cx({
                        [classes.inputStatusIcon]: true,
                      })}
                    />
                  )}
              </TableCell>
              <TableCell style={{ padding: "6px 6px 6px 0", height: "1px" }}>
                <Tooltip
                  title={t("modeling_inputs_v2.help.data_structure")}
                  placement={"left"}
                  arrow
                  PopperProps={{
                    style: { marginRight: "-6px" },
                  }}
                  classes={{ tooltip: classes.largeTooltip }}
                >
                  <div style={{display: "flex", height: "100%", cursor: "help"}}>
                    <div
                      className={cx({
                        [classes.inputStatusItem]: true,
                        [inputDrawerInput.stages.data_structure]: true,
                        ["not-next"]:
                        inputDrawerInput.stages.data_structure === "waiting",
                      })}
                    >
                      <AssignmentTurnedInRounded
                        style={{
                          marginRight: "6px",
                        }}
                      />
                      {t("modeling_inputs_v2.stages.data_structure")}
                    </div>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell rowspan={2}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    subtle
                    color={
                      ["error", "system_error"].includes(
                        inputDrawerInput.stages.data_structure,
                      ) ||
                      ["error", "system_error"].includes(
                        inputDrawerInput.stages.cross_linking,
                      )
                        ? "danger"
                        : inputDrawerInput.stages.data_structure ===
                              "warning" ||
                            inputDrawerInput.stages.cross_linking === "warning"
                          ? "warning"
                          : "client"
                    }
                    size={"sm"}
                    style={{ margin: "0 4px" }}
                    onClick={() => handleViewNotesClick()}
                    disabled={
                      isUploading ||
                      inputDrawerInput.stages.data_source !== "success"
                    }
                  >
                    <Notes style={{ width: "15px", height: "15px" }} />
                    {t("modeling_inputs_v2.last_update_notes")}
                  </Button>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align={"center"} style={{ width: "5%", paddingLeft: "0", paddingRight: "0" }}>
                {/*{"3"}*/}
                {inputDrawerInput.stages.cross_linking === "waiting" && (
                  <Timer
                    className={cx({
                      [classes.inputStatusIcon]: true,
                      ["waiting"]: !["success", "warning"].includes(
                        inputDrawerInput.stages.data_structure,
                      ),
                    })}
                  />
                )}
                {["success", "warning"].includes(
                    inputDrawerInput.stages.cross_linking,
                  ) && (
                    <Done
                      className={cx({
                        [classes.inputStatusIcon]: true,
                      })}
                    />
                  )}
                {["error", "system_error"].includes(
                    inputDrawerInput.stages.cross_linking,
                  ) && (
                    <HighlightOff
                      className={cx({
                        [classes.inputStatusIcon]: true,
                      })}
                    />
                  )}
              </TableCell>
              <TableCell style={{ padding: "6px 6px 6px 0", height: "1px" }}>
                <Tooltip
                  title={t("modeling_inputs_v2.help.data_cross_linking")}
                  placement={"left"}
                  arrow
                  PopperProps={{
                    style: { marginRight: "-6px" },
                  }}
                  classes={{ tooltip: classes.largeTooltip }}
                >
                  <div style={{display: "flex", height: "100%", cursor: "help"}}>
                    <div
                      className={cx({
                        [classes.inputStatusItem]: true,
                        [inputDrawerInput.stages.cross_linking]: true,
                        ["not-next"]: !["success", "warning"].includes(
                          inputDrawerInput.stages.data_structure,
                        ),
                      })}
                    >
                      <LinkRounded
                        style={{
                          marginRight: "6px",
                        }}
                      />
                      {t("modeling_inputs_v2.stages.data_cross_linking")}
                    </div>
                  </div>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
  // endregion

  return (
    <div>
      <FloatingLoading visible={pipelineInProgress || isUploading} />
      <GridContainer style={{ marginTop: "-40px", marginBottom: "25px" }}>
        <GridItem xs={12}>
          <div
            className={cx({
              [classes.inputsCardWrapper]: true,
              ["loading"]: inputsStatus.length === 0,
            })}
          >
            <Card
              style={{
                minWidth: "1200px",
                marginBottom: "0",
                position: "relative",
              }}
            >
              <CardHeader color="client" icon>
                <CardIcon color="client">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("modeling_inputs_v2.inputs_list")}
                </h4>
              </CardHeader>
              <CardBody style={{ padding: "0 10px 10px" }}>
                <div
                  style={{
                    marginTop: "8px",
                    width: "100%",
                    padding: "0",
                  }}
                >
                  {monthlyStatus.length === 0 && (
                    <ThreeDots
                      height="20px"
                      color={userDetails.implementation_color}
                      ariaLabel="loading"
                      wrapperStyle={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                      wrapperClass=""
                    />
                  )}
                  {monthlyStatus.length > 0 && monthSelectionTabs()}
                </div>
                {monthlyStatus.length > 0 &&
                  selectedMonthData &&
                  monthlyStatusDiv()}
              </CardBody>
            </Card>
            {monthlyStatus.length > 0 &&
              inputsStatus.length > 0 &&
              inputsComponent()}
          </div>
          {!!selectedMonth && inputsStatus.length === 0 && (
            <div
              style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ThreeDots
                color={userDetails.implementation_color}
                height={"40px"}
              />
            </div>
          )}
        </GridItem>
      </GridContainer>
      {inputDrawer()}
      {inputDrawerInput && displayNotesDialog()}
      {resetInputsDialog()}
    </div>
  );
}
