import { grayColor } from "assets/jss/material-dashboard-pro-react.js";

const helpTooltipStyle = {
  helpTag: {
    color: grayColor[6],
    margin: "0 8px",
    cursor: "help",
    fontSize: "16px",
    "&:not(.small):not(.large)": {
      fontSize: "16px",
    },
    "&.small": {
      fontSize: "14px",
    },
    "&.large": {
      fontSize: "22px",
    },
  },
};

export default helpTooltipStyle;
